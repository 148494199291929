import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Sports App Template | Design A Sports App | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/sports-app/"
    metaDescription="It's the ideal sports app template. Browse through the sporting app template to discover all the features of this stunning piece of design!"
    description="
    h2:Designing your own mobile sports app has never been easier
    <br/>
    Our stunning new sports app design <a:https://uizard.io/templates/>UI template</a> features sleek, modern elements that are sure to impress your audience and make your sports brand stand out. Perfect for those who are designing an app to follow MMA, martial arts, boxing, and more, our sporting app template has been crafted to help you design the perfect app for your own project.
    <br/>
    h3:A sports app design template for mobile, readily customizable
    <br/>
    With this modern and sleek design, you can create a professional-looking sporting app without any design knowledge. The 4-screen sporting app template is easy to customize and is designed to maximize user engagement. Simply sign up to Uizard today and bring your sports app prototype to life.
    <br/>
    h3:High-quality design is just a few clicks away
    <br/>
    Whether you're designing an app for sports news, sport communities, or you want to design a mobile sports blog app, with our template you can bring your own project to life with only a handful of edits. You could have a full <a:https://uizard.io/mockups/>UX/UI mockup</a> of your sports app created and shared with developers in the blink of an eye. Sign up to Uizard today for free and fast track your design flow.
    "
    pages={[
      "The Fight Pass home screen featuring the upcoming fights and games",
      "A leaderboard screens ranking athletes based on their latest performance",
      "An athlete details page",
      "A news page highlighting the latest news in the sports world",
    ]}
    projectCode="xVqB1oKebXSe0LvMY5r0"
    img1={data.image1.childImageSharp}
    img1alt="Screenshot of the sporting app template: cover"
    img2={data.image2.childImageSharp}
    img2alt="Screenshot of the sporting app template: home screen"
    img3={data.image3.childImageSharp}
    img3alt="Screenshot of the sporting app template: athlete profile screen"
    img4={data.image4.childImageSharp}
    img4alt="Screenshot of the sporting app template: athletes leaderboard screen"
    img5={data.image5.childImageSharp}
    img5alt="Image summary of the sporting app template template"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: { eq: "templates/sports-app/Sports-App-Cover.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: { eq: "templates/sports-app/Sports-App-Home.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: { eq: "templates/sports-app/Sports-App-Profile.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: { eq: "templates/sports-app/Sports-App-Athletes.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: { eq: "templates/sports-app/Sports-App-Summary.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
